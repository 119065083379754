/* PlaylistCreatedPage.css */

/* Main container styles for the Playlist Created Page*/
.playlist-created-page {
    position: relative;
    background-image: url("C:\Users\lordo\festival-playlist-app\festivalplaylistappfrontend\src\Photos\confetti.jpg"); /*Background Image */
    background-size: cover; /* Ensures the image covers the entire screen */
    background-position: center 40%;
    backdrop-filter: blur(5px);
    min-height: 100vh;
    align-items: center;
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Semi transparent overlay for better text readability */
.playlist-created-page::before {
    content: ''; /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: -1; /* Ensures the overlay is behind the content */
}

/* Header styles */
.playlist-created-page h1 {
    text-align: center;
    padding: 20px;
    margin-bottom: 10px;
    font-size: calc(50px + 2vmin);
}

/* Paragraph styles */
.playlist-created-page p {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: calc(10px + 2vmin);
}

/* Styling for the return home button */
.return-home-button {
    padding: 20px 20px;
    font-size: 2.5em;
    color: white;
    background-color: #1db91d; /* Spotify green */
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Hover effect for "Return Home" button */
.return-home-button:hover {
    background-color: #45a049;
}