/* SearchResultsPage.css */

/* Search Result Page styles */
.search-result {
    position: relative;
    background-image: url("C:\Users\lordo\festival-playlist-app\festivalplaylistappfrontend\src\Photos\FredAgain.jpg");
    background-size: cover;
    background-position: center 80%;
    min-height: 100vh;
    backdrop-filter: blur(5px);
    align-items: center;
    color: white;
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 150px;
}

/* Search result page header styles */
.search-result h1 {
    text-align: center;
    font-size: clamp(1.5rem, 2.5vw, 3rem);
}

/* Search result page subheader styles */
.search-result h2 {
    text-align: center;
    font-size: clamp(1rem, 1.5vw, 2rem);
}

/* Semi transparent dark overlay for better text readability */
.search-result::before {
    content: ''; /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay */
    z-index: -1; /* Ensures the overlay is behind the content */
}

/* Main artist button container styles */
.artist-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px; /* Space between buttons */
    width: fit-content;
}

/* Artist button styles */
.artist-button {
    border: none;
    background-color: rgba(46, 46, 46, 0.705);
    color: white;
    font-size: clamp(.75rem, 1vw, 1.5rem);
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

/* Artist button hover effect */
.artist-button:hover, .artist-button:active {
    background-color: rgba(0,0,0,.9);
}

/* Artist button selected effect */
.artist-button.selected {
    background-color: rgba(30, 215, 95, 0.801);
}

/* Playlist name user input text box styles */
.playlist-name-input {
    padding: 13px;
    margin-top: 20px;
    border: 4px solid #ccc;
    border-radius: 4px;
    font-size: clamp(1rem, 1.5vw, 2rem);
    height: 50%;
}

/* Generate playlist button styles */
.generate-playlist-button {
    padding: 15px;
    background-color: #4CAF50;
    color: white;
    border: 1px solid #047914;
    border-radius: 4px;
    font-size: clamp(1rem, 1.5vw, 2rem);
    cursor: pointer;
}

/* Generate playlist button hover styles */
.generate-playlist-button:hover {
    background-color: #45a049;
}

/* Generate playlist button disabled styles */
.generate-playlist-button:disabled {
    background-color: #055f11;
}

/* Responsive adjustments for small screens */
@media (max-width: 600px) {
    .search-result {
        padding: 25;
    }
    .artist-buttons-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding: 5px;
    }

    .artist-button {
        font-size: clamp(0.6rem, 2vw, 0.8rem); /* Larger font size for small screens */
        padding: 10px 5px; /* Slightly larger padding */
        flex: 0 0 calc(25% - 10px); /* Full-width buttons for smaller screens */
        margin-bottom: 5px; /* Add some space between wrapped buttons */
        white-space: normal;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .playlist-name-input, .generate-playlist-button {
        width: 100%; /* Full-width input and button */
    }
}