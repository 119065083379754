/* Home.css */

/* Home Page styles */
.home-page {
    position: relative;
    background-image: url("C:\Users\lordo\festival-playlist-app\festivalplaylistappfrontend\src\Photos\miami-gallery-2019-5-1600x1066.jpg"); /* Background Image */
    background-size: cover;
    background-position: center 20%;
    backdrop-filter: blur(5px);
    min-height: 100vh;
    align-items: center;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Tint Background */
.home-page::before {
    content: ''; /* Required for pseudo-elements */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay */
    z-index: -1; /* Ensures the overlay is behind the content */
}

/* Search input styles */
.search-input {
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
    height: 50%;
}

/* Search button styles */
.search-button {
    padding: 15px 20px;
    background-color: #4CAF50;
    color: white;
    border: 1px solid #047914;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
}

.search-button:hover {
    background-color: #45a049; /* Slightly darker green hover */
}

/* Home Page Header Styles*/
.home-page-header h1 {
    text-align: center;
    font-size: calc(50px + 2vmin);
}